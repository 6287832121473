import * as React from "react";
import axios from "axios";
import { useReadLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import useAnalytics from "~/hooks/useAnalytics";
import { addBookmark, deleteBookmark } from "~/utils/bookmarkUtils";
import { ResultTypeIndex } from "~/features/Account/Bookmarks/types";
import Button from "~/ui/Button/Button";
import Image from "~/ui/Image/Image";

interface ITextItemProps {
    text: Text;
    storedTexts: string;
    setTexts: React.Dispatch<React.SetStateAction<string>>;
    markText?: string;
}

export interface Text {
    id: string;
    pageID: number;
    pageNum: number;
    company: string;
    year: number;
    documentType: string;
    documentName: string;
    content: string;
    highlight: string;
    thumbnail: string;
}

const TextItem: React.FunctionComponent<ITextItemProps> = ({ text, storedTexts, setTexts, markText }) => {
    const [isInCart, setIsInCart] = React.useState<boolean>(false);
    const token = useReadLocalStorage("_t") as string;
    const { t } = useTranslation();
    const { sp_click } = useAnalytics();

    React.useEffect(() => {
        const bookmarks = storedTexts !== "" ? JSON.parse(storedTexts) : [];
        const match = bookmarks && bookmarks.find((bookmark: Text) => bookmark.pageID === text.pageID);
        const inCart = !!match;

        setIsInCart(inCart);
    }, [storedTexts]);

    const highlightText = (contentText: string, searchText?: string, showFullText?: boolean) => {
        if (!searchText || !contentText) return contentText;

        let HLText = contentText;

        if (!showFullText) {
            const index1 = contentText.indexOf(searchText);

            if (index1 >= 0 && index1 < 300) {
                HLText = contentText.substring(0, 300) + "...";
            } else {
                HLText = contentText.substring(0, 250) + "... " + text.highlight + "...";
            }
        }

        const regex = new RegExp(searchText, "gi");
        const newText = HLText.replace(regex, `<mark>$&</mark>`);
        return <span dangerouslySetInnerHTML={{ __html: newText }} />;
    };

    const toggleFavorite = () => {
        // Update local storage
        const bookmarks = storedTexts !== "" ? JSON.parse(storedTexts) : [];
        const updatedBookmarks = isInCart
            ? bookmarks.filter((bookmark: Text) => bookmark.pageID !== text.pageID)
            : [...bookmarks, text];

        setTexts(JSON.stringify(updatedBookmarks));

        // Update server data
        isInCart ? deleteBookmark(text.pageID, ResultTypeIndex.Text) : addBookmark(text.pageID, ResultTypeIndex.Text);
        setIsInCart(!isInCart);

        if (!isInCart) {
            sp_click(
                "N",
                "bookmark",
                "Text bookmark",
                `${text.company} - ${text.documentType} ${text.year}, Page ${text.pageNum}`,
                {
                    _title: "Explore Text results",
                }
            );
        }
    };

    const downloadDocument = () => {
        const region = import.meta.env.VITE_REGION === "us" ? "loadUS" : "loadFR";
        const noDownload = () => alert("This document is not available for download.");

        sp_click(
            "N",
            "download",
            "Text download",
            `${text.company} - ${text.documentType} ${text.year}, Page ${text.pageNum}`,
            {
                _title: "Explore Text results",
            }
        );

        try {
            axios
                .get(`${import.meta.env.VITE_API_HOST}/api/docpreview/${region}?pageid=${text.pageID}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: "blob",
                })
                .then((response) => {
                    if (response.status === 200) {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data], { type: response.headers["content-type"] })
                        );
                        const link = document.createElement("a");
                        link.href = url;

                        link.setAttribute("download", text.documentName || "pdf");
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    } else {
                        console.error("Unexpected status code:", response.status);
                        noDownload();
                    }
                })
                .catch((e: any) => {
                    console.error("Error in axios request:", e);
                    noDownload();
                });
        } catch (e) {
            console.error("A problem occurred while fetching the document.");
            noDownload();
        }
    };

    const previewDocument = () => {
        const region = import.meta.env.VITE_REGION === "us" ? "loadUS" : "loadFR";
        const noPreview = () => alert("Document preview not available for this item.");

        try {
            axios
                .get(`${import.meta.env.VITE_API_HOST}/api/docpreview/${region}?pageid=${text.pageID}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: "blob",
                })
                .then((response) => {
                    if (response.status === 200) {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data], { type: response.headers["content-type"] })
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("target", "_blank");
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    } else {
                        console.error("Unexpected status code:", response.status);
                        noPreview();
                    }
                })
                .catch((e: any) => {
                    console.error("Error in axios request:", e);
                    noPreview();
                });
        } catch (e) {
            console.error("A problem occurred while fetching the document.");
            noPreview();
        }
    };

    return (
        <div className="rounded-lg shadow-sm p-4 bg-white w-full flex">
            <div className="flex flex-grow items-center">
                <Image
                    src={`${import.meta.env.VITE_API_HOST}/${text.thumbnail}`}
                    alt={`${text.company} ${text.documentType} ${text.year}`}
                    className="w-[248px] max-h-[320px]"
                />
                <div className="flex-1 px-4 2xl:px-8 py-4 gap-y-4 h-full flex flex-col justify-between">
                    <div>
                        <h2>{highlightText(text.company, markText, true)}</h2>
                        <h4>{highlightText(`${text.documentType}, ${text.year}`, markText, true)}</h4>
                        <p className="text-sm">Page {text.pageNum}</p>
                    </div>
                    <div>
                        {text.highlight && (
                            <p className="text-foreground-light text-sm" style={{ overflowWrap: "anywhere" }}>
                                {highlightText(text.content, markText, false)}
                            </p>
                        )}
                    </div>
                    <div className="flex row mt-2">
                        <Button color="primary" icon="download" className="w-32 2xl:w-44" onPress={downloadDocument}>
                            {t("download")}
                        </Button>
                        <Button color="light" icon="image" className="w-32 2xl:w-44 ml-4" onPress={previewDocument}>
                            {t("preview")}
                        </Button>
                    </div>
                </div>
            </div>
            <Button
                icon="favorite"
                iconFilled
                color="secondary"
                elevated
                noHover
                className={`${isInCart ? "!text-primary" : ""}`}
                onPress={toggleFavorite}
            />
        </div>
    );
};

export default TextItem;
