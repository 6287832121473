const TermsAndConditions = (
    <div>
        <h3>Labrador Regulated Information Transparency, Inc.</h3>

        <p>
            These Terms and Conditions are applicable to all clients of Labrador Regulated Information Transparency
            Inc., a Delaware corporation. By signing a proposal from Labrador, you are accepting the Terms and
            Conditions set forth in this document.
        </p>

        <h3>Quotes, Orders, Service, Payment and Delivery Terms</h3>

        <p>
            1. Quoted prices for services and goods are based upon specifications supplied by the Client. If the scope
            of services and goods required by the Client is modified from that presented in the quotation, Labrador’s
            price may be amended. All prices contained in this quotation are subject to review and potential adjustment
            after 60 days.
        </p>

        <p>
            2. Paper prices may be subject to adjustment based on changes in availability and the price at the time a
            project commences if other than the date of on which the quotation is based.
        </p>

        <p>Unless otherwise stated, all prices are quoted F.O.B. manufacturing plant.</p>

        <p>
            Custom or significantly large paper orders will be quoted with a date on which the paper order must be
            placed on their behalf. Any changes in scope after that date may result in additional charges to the Client.
        </p>

        <p>
            3. In the absence of shipping and packing instructions, Labrador shall use its own discretion in choice of
            carrier and method of packing. Labrador shall not be responsible for insuring shipments unless specifically
            requested by the Client and any insurance so requested shall be at the Client’s sole expense.
        </p>

        <p>
            4. Upon receipt of shipment, the Client shall immediately inspect the goods provided. Unless the Client
            provides Labrador with written notice of any claim or shortages of or defects in the goods, including any
            claim relating to quantity, weight, condition, loss or damage thereto, within 48 hours after receipt of
            shipment, such goods shall be deemed finally inspected, checked and accepted by the Client.
        </p>

        <p>
            5. Labrador will present the Client with an invoice for services completed on an individual project basis or
            on such other bases as Labrador and Client may mutually agree. Invoices are to be paid in full by the Client
            within 30 days of the invoice date.
        </p>

        <p>
            6. Labrador’s Intellectual Property (layout, graphics) remains the property of Labrador and may not be
            reused or communicated to a third party.
        </p>

        <p>
            7. Source files pertaining to any services or products from Labrador are not available for distribution or
            release to clients or third parties.
        </p>

        <p>
            8. Labrador shall not be liable or deemed liable to be in default for any delay or failure to perform
            resulting directly or indirectly from any cause beyond Labrador’s reasonable control.
        </p>

        <p>
            9. Labrador’s liability for its services and goods shall be limited to repairing or replacing goods found by
            Labrador to be beyond the standard deviations of the printing profession. At Labrador’s discretion, a refund
            of the purchase price of the services or goods, or parts thereof, may be applied.
        </p>

        <p>
            10. Any sales, use or similar taxes, export charges, fees or other levies, taxes, duties, governmental
            charges or surcharges now or hereafter imposed under any present or future law in connection with the
            production, sale, delivery, use or proceeds of goods provided shall be payable by Client.
        </p>

        <h3>Indemnification, Disclaimer of Warranties and Limitation Of Liability</h3>

        <p>
            11. This price quotation and the terms contained herein constitute the entire agreement between Labrador and
            the Client and no other terms shall be binding unless accepted in writing. No alteration or amendment to the
            terms and conditions listed herein shall be binding on Labrador except if made in writing duly executed by
            an officer of Labrador.
        </p>

        <p>
            12. The client agrees to indemnify, defend and hold Labrador, its officers, directors, employees, agents,
            licensors and suppliers harmless from and against all claims, liabilities, losses, expenses, damages and
            costs, including reasonable attorneys’ fees, resulting from (i) any violation of these Terms, including but
            not limited to any activity related to Client’s use of the Services (including negligent or wrongful conduct
            or communication of personal access codes and passwords) by Client or any other person accessing or using
            Services (ii) a claim that an image submitted to and printed by Labrador is prohibited subject matter or
            infringes third party intellectual property rights.
        </p>

        <p>
            13. Labrador agrees to indemnify, defend and hold Client, its officers, directors, employees, agents,
            licensors and suppliers harmless from and against all claims, liabilities, losses, expenses, damages and
            costs, including reasonable attorneys’ fees, resulting from (i) violation of these Terms by Labrador or (ii)
            a claim that Labrador’s goods or services violate any patent, copyright, mask right, trade secret or other
            proprietary right.
        </p>

        <p>
            14. To the fullest extent permitted by applicable law, except as provided herein, Labrador expressly
            disclaims all warranties and conditions of any kind, whether express or implied including, but not limited
            to, implied warranties of fitness for a particular purpose and non-infringement.
        </p>

        <p>
            15. To the fullest extent permitted by applicable law, under no circumstances shall Labrador be liable for
            any indirect, incidental, special, punitive or consequential damages.
        </p>

        <p>
            16. The parties agree that these Terms and any claims hereunder shall be governed by and subject to the
            state and federal laws of the state of New York, without regard to its conflict of law provisions, and
            hereby consent to jurisdiction and venue in the courts of the Southern District of New York located in New
            York, New York.
        </p>
    </div>
);

export default TermsAndConditions;
