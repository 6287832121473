import TermsAndConditions from "./TermsAndConditionsEN";
import PrivacyPolicy from "./PrivacyPolicyEN";
import LegalNotice from "./LegalNoticeEN";

const documentListUS = {
    en: [
        { title: `legalDocs.terms&Conditions`, content: TermsAndConditions },
        { title: `legalDocs.privacyPolicy`, content: PrivacyPolicy },
        { title: `legalDocs.legalNotice`, content: LegalNotice },
    ],
    fr: [],
};

export default documentListUS;
