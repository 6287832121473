import { useState, useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { Tab } from "@headlessui/react";
import { ITextItem, ResultTypeIndex } from "./types";
import Bookmark from "~/components/Bookmark";

const TextBookmarks = () => {
    const STORAGE_NAME = "texts";
    const [storedTexts, setStoredTexts] = useLocalStorage(STORAGE_NAME, "");
    const [texts, setTexts] = useState<ITextItem[]>([]);

    useEffect(() => {
        // Support for saved text bookmarks on previous version
        // Convert old data format to new text data format and update storage
        if (storedTexts && storedTexts !== "") {
            let updateStorageFlag = false;
            const textArr = JSON.parse(storedTexts).map((text: any) => {
                if (text.SourceDocumentID) {
                    updateStorageFlag = true;
                    return {
                        id: text.id,
                        pageID: text.PageID,
                        pageNum: text.PageNumber,
                        company: text.CompanyName,
                        year: text.Year,
                        documentType: text.DocumentType,
                        documentName: text.SourceDocumentName,
                        content: text.Content,
                        highlight: "",
                        thumbnail: text.ThumbnailImage.substring(1),
                    } as ITextItem;
                }
                return text;
            });

            if (updateStorageFlag) {
                setStoredTexts(JSON.stringify(textArr));
            }
        }
    }, []);

    useEffect(() => {
        if (storedTexts && storedTexts !== "") {
            setTexts(JSON.parse(storedTexts));
        } else {
            setTexts([]);
        }
    }, [storedTexts]);

    return (
        <Tab.Panel className="w-full">
            <div className="grid grid-cols-3 gap-2 p-4">
                {texts.map((text: ITextItem, index: number) => (
                    <Bookmark
                        key={`bookmark-text-${index}`}
                        imgPath={`${import.meta.env.VITE_API_HOST}/${text.thumbnail}`}
                        alt={`${text.company} ${text.documentType}-${text.year} Page ${text.pageNum}`}
                        id={text.pageID}
                        type={ResultTypeIndex.Text}
                        storage={STORAGE_NAME}
                        storageID="pageID"
                    />
                ))}
            </div>
        </Tab.Panel>
    );
};

export default TextBookmarks;
