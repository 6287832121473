const PrivacyPolicy = (
    <div>
        <p>
            <strong>1.1 Access</strong>.&nbsp;To access certain features of this Site you may be required to register.
            During the registration process, either you or your employer will be required to provide Labrador&nbsp;with
            certain information, including your name, employer, title, business address, business telephone number and
            business e-mail address (“Registration Data”). This Registration Data will be shared only with
            Labrador&nbsp;companies, by you or your employer, to authorize your use of this Site. While you are
            accessing this Site our Web servers may recognize your: (a) domain name; (b) ISP’s domain name; (c) IP
            address; (d) browser type; and (e) operating system. If you contact us with a technical question, we may
            collect certain information about your systems, including: (a) your browser type, version and settings (e.g.
            Java and cookie settings); (b) connectivity information (e.g. SSL/HTTPS compatibility, bandwidth capacity);
            and browser plug-in information (e.g. do you have Adobe, what is your media player, can you open Flash
            files, etc.). It is also possible that, upon agreement by you, Labrador&nbsp;’s system may place certain
            programs, scripts or packets of information on your computer. These items are used solely to carry out the
            functions of the Site and for no other purpose.
        </p>

        <p>
            <strong>1.2 Use of Information</strong>.&nbsp;Except for (i) Registration Data, which will be shared only
            with Labrador, by you or your employer, to authorize your use of this Site, all information collected from
            you and recorded about you in connection with your use of this Site will be shared with Labrador.
            Registration Data may be used by Labrador: (a) in order to allow us to provide you with the products and
            services included within or accessible from this Site; (b) in order for us to provide you with access to or
            from the linked sites; (c) for our own marketing and/or research and development purposes; and (d) in
            connection with our client services and client management systems.
        </p>

        <p>
            <strong>1.3 Disclosure of Information</strong>.&nbsp;Labrador&nbsp;understands that privacy is important to
            you. Except as otherwise noted herein, Labrador&nbsp;will not rent or sell information we have collected
            from or about you without your permission. For the purposes specified in the preceding paragraph, we may
            transfer or disclose Registration Data, Access Data and Visitor Data to any Labrador&nbsp;company, wherever
            located in the world. We may disclose Registration Data, Access Data and Visitor Data to third parties who
            are contracted to perform services on behalf of Labrador, such as those who assist Labrador&nbsp;in bringing
            you this Site and providing you with certain features and functionality included within or accessible via
            this Site. We may also disclose Registration Data, Access Data and Visitor Data to third parties in
            connection with their providing you access to this Site or to allow you to access the linked sites.
            Disclosures to these third parties will be subject to confidentiality agreements and, where required,
            governed by contract in the form required by law. Labrador&nbsp;may also be required, and reserves the
            right, to disclose information to governmental, regulatory or self-regulatory entities or agencies in
            response to regulatory inquiries or to comply with applicable laws, rules, regulations, court orders,
            subpoenas or other legal processes.
        </p>

        <p>
            <strong>1.4 Consent.</strong>&nbsp;By (a) agreeing to these Terms of Usage, which include this Privacy
            Statement, or (b) by using this Site, and, in either case, providing any information that may be required,
            invited or otherwise collected by us as set forth above, you freely consent to Labrador&nbsp;processing your
            information in the United States and any other countries and territories for the purposes set out in these
            Terms of Usage, and you also consent to the transfer of your information for such purposes to any
            Labrador&nbsp;company wherever such entity may from time to time be located and to any third parties as
            described above and in accordance with applicable law and regulations. If you do not wish Labrador&nbsp;to
            collect any of your information or do not agree with any of the terms and conditions of these terms of
            Usage, you should not use and should exit this Site. If after registering with Labrador, you desire to
            withdraw the consent granted in this Section 3.4 for all future use of your information by Labrador, you
            must notify Labrador&nbsp;in writing at the address listed below and immediately cease use of this Site.
        </p>

        <p>
            <strong>1.5 Inquiries</strong>.&nbsp;If you have any questions regarding this Privacy Statement or your
            information that is held by us, please contact Labrador&nbsp;in writing using the contact information
            provided below. If we receive a request regarding your personal information held by us, we will use
            reasonable means to provide you with such information that we can reasonably compile. You will be given the
            opportunity to rectify any inaccuracies in such information.
        </p>

        <p>
            <strong>1.6 Encryption</strong>.&nbsp;Labrador&nbsp;may use encryption technology to protect certain
            transmissions of data to/from this Site, but e-mail and other communications, unless otherwise noted on this
            Site, are not encrypted to/from this Site. Therefore, you should not send any personal or identifying
            information, such as account numbers, credit card numbers, Social Security numbers, passwords, etc., to
            Labrador&nbsp;via e-mail. By utilizing e-mail or other electronic communication means you acknowledge that
            you have no expectation of privacy with respect to the information delivered thereby and that
            Labrador&nbsp;will not be responsible for any loss or damage that could result from interception by third
            parties of any information so sent.
        </p>

        <p>
            <strong>1.7 Linked Sites.</strong>&nbsp;Please remember that when you leave this Site and access a linked
            site you will be subject to the terms of use and privacy policies, if any, of the linked site that you are
            visiting. Labrador will have no control over or liability for claims arising out of the terms of use or
            privacy policies of such Linked Sites.
        </p>

        <p>
            <strong>1.8 Contact Information.</strong>&nbsp;In the event you have any questions regarding these Terms of
            Use, this Privacy Statement or your information that is held by us you may contact us in writing in the
            United States at Labrador, Attn: President, 530 Means Street, Suite 410, Atlanta, GA 30318.
        </p>
    </div>
);

export default PrivacyPolicy;
